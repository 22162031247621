import { type Allergen } from '@owner/menu-entities'

import {
  type BooleanFilterTranslation,
  type DropdownFilterTranslation,
} from '@/components/filtering'

import { type ResourceTypes } from '../types'

interface InputTranslation {
  label: string
  description?: string
  placeholder?: string
}

export interface MenuTranslations {
  allergen: Record<Allergen, string>
  errors: Record<string, string>
  filters: Record<string, BooleanFilterTranslation | DropdownFilterTranslation>
  inputs: Record<string, InputTranslation>
  labels: Record<string, string>
  notices: Record<string, string>
  resources: Record<ResourceTypes, string>
  selectors: Record<string, string>
  tooltips: Record<string, string>
}

export const menuEn: MenuTranslations = {
  allergen: {
    dairy: 'Dairy',
    eggs: 'Eggs',
    fish: 'Fish',
    gluten: 'Gluten',
    mango: 'Mango',
    peanuts: 'Peanuts',
    sesame: 'Sesame',
    shellfish: 'Shellfish',
    soy: 'Soy',
    'tree-nuts': 'Tree Nuts',
  },
  errors: {
    save: 'Unable to save {resourceName}.',
  },
  filters: {
    alcohol: {
      label: 'Alcohol',
      options: {
        false: 'No Alcohol',
        true: 'Alcohol',
      },
    },
    allowInstructions: {
      label: 'Allow Instructions',
      options: {
        false: 'No Instructions',
        true: 'Instructions',
      },
    },
    available: {
      label: 'Availability',
      options: {
        false: 'Not Available',
        true: 'Available',
      },
    },
    campaign: {
      label: 'Associated with automation',
      options: {
        basic: 'Basic',
        welcome: 'Welcome',
        winback: 'Winback',
      },
    },
    glutenFree: {
      label: 'Gluten Free',
      options: {
        false: 'Not Gluten Free',
        true: 'Gluten Free',
      },
    },
    hasCategories: {
      label: 'Categories',
      options: {
        false: 'No Categories',
        true: 'Has Categories',
      },
    },
    hasItems: {
      label: 'Items',
      options: {
        false: 'No Items',
        true: 'Has Items',
      },
    },
    hasModifierSets: {
      label: 'Modifier Sets',
      options: {
        false: 'No Modifier Sets',
        true: 'Has Modifier Sets',
      },
    },
    hasModifiers: {
      label: 'Modifiers',
      options: {
        false: 'No Modifiers',
        true: 'Has Modifiers',
      },
    },
    hasPreselectedModifiers: {
      label: 'Preselected Modifiers',
      options: {
        false: 'No Preselected Modifiers',
        true: 'Has Preselected Modifiers',
      },
    },
    hasUpsellCategories: {
      label: 'Upsell Categories',
      options: {
        false: 'No Upsell Categories',
        true: 'Has Upsell Categories',
      },
    },
    linkedToPos: {
      label: 'Linked to POS',
      options: {
        false: 'Not linked',
        true: 'Linked',
      },
    },
    popular: {
      label: 'Popular',
      options: {
        false: 'Not Popular',
        true: 'Popular',
      },
    },
    promotional: {
      label: 'Promotional',
      options: {
        false: 'Not Promotional',
        true: 'Promotional',
      },
    },
    taxable: {
      label: 'Tax',
      options: {
        false: 'Not Taxable',
        true: 'Taxable',
      },
    },
    topping: {
      label: 'Topping',
      options: {
        false: 'Not Topping',
        true: 'Topping',
      },
    },
    toppings: {
      label: 'Toppings',
      options: {
        false: 'Not Toppings',
        true: 'Toppings',
      },
    },
    type: {
      label: 'Menu Type',
      options: {
        catering: 'Catering',
        olo: 'Online Ordering',
      },
    },
    upsell: {
      label: 'Upsell',
      options: {
        false: 'Not Upsell Item',
        true: 'Is Upsell Item',
      },
    },
    vegetarian: {
      label: 'Vegetarian',
      options: {
        false: 'Not Vegetarian',
        true: 'Vegetarian',
      },
    },
  },
  inputs: {
    alcohol: {
      label: 'Alcohol',
    },
    allergens: {
      label: 'Allergens',
    },
    allowInstructions: {
      description:
        'Customers will be able to add comments to items when placing an order (eg. ‘no onions’).',
      label: 'Allow Special Instructions',
    },
    amount: {
      description: "Overridden by the '{variationGroupName}' variation group.",
      label: 'Amount',
    },
    available: {
      description: 'Only available items will show on your menu.',
      label: 'Available',
    },
    categories: {
      description:
        'Categories contain a list of items (eg. Appetizers, Entrees, Desserts).',
      label: 'Categories',
    },
    description: {
      label: 'Description',
    },
    displayName: {
      description: 'This is the name your customers will see.',
      label: 'Display Name',
    },
    glutenFree: {
      label: 'Gluten Free',
    },
    image: {
      label: 'Photo',
    },
    menuAvailability: {
      description:
        'The days and hours the menu is available for your customers.',
      label: 'Availability Hours',
    },
    modifierSelection: {
      description:
        'The minimum and maximum quantity of this modifier that a customer must select when ordering.',
      label: 'Selection',
    },
    modifierSetSelection: {
      description:
        'The minimum number of modifiers a customer must select from this modifier group when ordering and the maximum number of modifiers they can select.',
      label: 'Selection',
    },
    modifierSets: {
      description:
        'Modifiers allow customers to customize an item. You might have a modifier group like “Toppings”.',
      label: 'Modifier Groups',
    },
    modifierSetsNested: {
      description: 'Nested modifiers allow customers to customize a modifier.',
      label: 'Nested Modifier Groups',
    },
    modifiers: {
      description:
        'Modifiers allow customers to customize an item (eg. Pepperoni, No Onions, Sprinkles).',
      label: 'Modifiers',
    },
    name: {
      label: 'Internal Name',
    },
    points: {
      label: 'Points to Redeem',
      placeholder: 'Not Redeemable',
    },
    popular: {
      description:
        'Popular items are highlighted on your website and marketing.',
      label: 'Popular Item',
    },
    taxable: {
      label: 'Apply sales tax for this item',
    },
    upsell: {
      description:
        'Upsell items will be promoted to your guests as they order.',
      label: 'Upsell Item',
    },
    vegetarian: {
      label: 'Vegetarian',
    },
  },
  labels: {
    'custom-internal': 'Use a different name internally',
    'custom-internal-description':
      'This is the name you and your staff will see on the dashboard, tablet and printed tickets.',
    'more-options': 'More Options',
    options: 'Options',
    promotion: 'Promotion',
    'resource-created': '{resourceName} Created',
    'resource-updated': '{resourceName} Updated',
    'selection-maximum': 'Maximum',
    'selection-minimum': 'Minimum',
    'selection-unlimited': 'No Limit',
  },
  notices: {
    'pos-connected':
      'Connected to your POS menu. Most changes must be made directly on that platform and only a subset of updates can be made here.',
  },
  resources: {
    category: 'Category | Categories',
    coupon: 'Coupon | Coupons',
    item: 'Item | Items',
    menu: 'Menu | Menus',
    modifier: 'Modifier | Modifiers',
    'modifier-set': 'Modifier Group | Modifier Groups',
  },
  selectors: {
    loading: 'Loading...',
  },
  tooltips: {
    delete: 'Delete',
    duplicate: 'Duplicate',
  },
}
