
{
  "links": {
    "terms": "Terms of use",
    "privacy": "Privacy policy",
    "unsubscribe": "Unsubscribe",
    "feedback": "Share feedback",
    "owner": "Made with Owner"
  }
}
