import * as Sentry from '@sentry/vue'
import Vue from 'vue'

const VITE_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
const MODE = import.meta.env.MODE

const APP_VERSION = import.meta.env.APP_VERSION

if (MODE === 'production' || MODE === 'staging')
  Sentry.init({
    Vue,
    attachStacktrace: true,
    dsn: VITE_SENTRY_DSN,
    environment: MODE,
    integrations: [new Sentry.Replay(), new Sentry.BrowserTracing()],

    release: `${MODE}@${APP_VERSION}`,

    // Capture replay for 100% of sessions with an error
    // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
    replaysOnErrorSampleRate: 1.0,

    // Capture replay for 10% of all sessions,
    // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
    replaysSessionSampleRate: 0.1,

    // Sets 10% of transactions to be reported
    // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/sampling/#setting-a-uniform-sample-rate
    tracesSampleRate: 0.1,

    // Tracks all components
    // https://docs.sentry.io/platforms/javascript/guides/vue/features/component-tracking/#trackcomponents
    trackComponents: true,
  })
