import { ConfiguredClient } from '@/api'
import { transformToOlympusNavItems } from '@/features/website-builder/utils/navItems'

import {
  type WebsiteResource,
  type WebsiteResourceUpdate,
} from '../WebsiteBuilder.types'

export const updateResourceHandler = async (
  id: string,
  { enabled, sections, funnel, title, brand, nav, theme }: WebsiteResourceUpdate
): Promise<WebsiteResource> => {
  const [pageUpdateResult, funnelNavUpdateResult] = await Promise.all([
    ConfiguredClient.funnel.v1.pages.page.updatePage({
      body: {
        enabled,
        sections,
        title,
      },
      params: {
        pageId: id,
      },
    }),
    ConfiguredClient.funnel.v1.brands.brand.updateFunnel({
      body: {
        navigation: {
          ...funnel.navigation,
          items: transformToOlympusNavItems(nav),
        },
      },
      params: {
        brandId: brand.id,
      },
    }),
  ])

  return {
    ...pageUpdateResult,
    brand,
    funnel: funnelNavUpdateResult,
    nav,
    theme,
  }
}
