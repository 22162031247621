<i18n locale="en">
  {
    "title": {
      "single": "Our location",
      "multi": "Our locations"
    }
  }
</i18n>

<template>
  <PreviewSheet class="locations-preview w-full h-full" radius="0">
    <OwnStack spacing="4">
      <OwnStack spacing="12">
        <OwnStack justify="between" align="center" row>
          <PreviewTypography :text="title" variant="heading2" />
          <LocationsPreviewNavButtons v-if="multiLoc" />
        </OwnStack>
        <LocationsPreviewPillSelector v-if="multiLoc" />
      </OwnStack>
      <LocationsPreviewInfoSheet
        v-if="firstLocation"
        :location="firstLocation"
      />
    </OwnStack>
  </PreviewSheet>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { Location } from '@/core'
import { PreviewSheet, PreviewTypography } from '@/lib/builder'
import { useMappedGetter } from '@/store'
import { OwnStack } from '@/ui'
import { useI18n } from '@/utils'

import type { LocationsSection } from '../../../WebsiteBuilder.types'

import LocationsPreviewInfoSheet from './LocationsPreviewInfoSheet.vue'
import LocationsPreviewNavButtons from './LocationsPreviewNavButtons.vue'
import LocationsPreviewPillSelector from './LocationsPreviewPillSelector.vue'

defineProps<{
  content: LocationsSection
}>()

const activeLocations = useMappedGetter<Location[]>(
  'core/brand/active/locations'
)

const multiLoc = computed(() => activeLocations.value.length > 1)

const i18n = useI18n()

const title = computed(() => {
  return i18n.$t(multiLoc.value ? 'title.multi' : 'title.single')
})

const firstLocation = computed(() => activeLocations.value[0])
</script>

<style lang="scss" scoped>
.locations-preview {
  padding: 120px;
}
</style>
