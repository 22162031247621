import { websiteBuilderModuleRouter } from '../builder/router/websiteBuilderModuleRouter'

export const websiteBuilderRouter = [
  ...websiteBuilderModuleRouter,
  {
    component: async () => import('../views/WebsiteBuilderPagesView.vue'),
    meta: {
      title: 'website.pages',
    },
    name: 'brand.website.pages.home',
    path: 'pages',
  },
  {
    component: async () =>
      import('../views/WebsiteBuilderAnnouncementsView.vue'),
    meta: {
      title: 'website.announcements',
    },
    name: 'brand.website.announcements',
    path: 'announcements',
  },
]
