import { PhTextbox } from 'phosphor-vue/dist/phosphor-vue.esm'

import { type BuilderBlockModule } from '@/lib/builder'
import i18n from '@/plugins/i18n'

import type {
  Form,
  TextFieldSection,
  TextFieldSectionCreate,
  TextFieldSectionUpdate,
} from '../../../types/FormBuilder.types'
import { formBlockMethods } from '../common/methods'

import TextInputEditor from './editor/TextInputEditor.vue'
import { createTextInputData } from './methods/createTextInputData'
import TextInputPreview from './preview/TextInputPreview.vue'

export const TextInputBlockModule: BuilderBlockModule<
  TextFieldSection,
  TextFieldSectionCreate,
  TextFieldSectionUpdate,
  Form
> = {
  createData: createTextInputData,
  editor: TextInputEditor,
  meta: [
    {
      icon: PhTextbox,
      key: 'text-field',
      label: i18n.t('form-builder.blocks.text-field'),
      sort: 1,
    },
  ],
  methods: formBlockMethods,
  preview: TextInputPreview,
}
