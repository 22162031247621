<i18n locale="en">
{
  "unavailable": "Not available when sending to all locations.",
  "method-options": {
    "ai": "Make it with AI",
    "custom": "Make it yourself"
  },
  "generate": "Generate Email"
}
</i18n>

<template>
  <OwnStack class="template-sidebar" spacing="8">
    <template v-if="showLocationSelector">
      <OwnStack spacing="2">
        <TitleBlock :title="templateConfig.heading" />
        <LocationSelector v-model="selectedLocation" />
      </OwnStack>
      <OwnRule type="dashed" />
    </template>

    <OwnTouchRadioGroup v-model="selectedMethod" :options="methodOptions" />
    <OwnRule type="dashed" />

    <OwnStack v-if="selectedMethod === 'custom'" spacing="3">
      <OwnTouchRadioGroup
        v-model="selectedOption"
        :options="availableOptions"
      />

      <OwnCard
        v-for="option of unavailableOptions"
        :key="option.value"
        class="template-sidebar__unavailable-option"
      >
        <OwnStack spacing="3" align="center" row>
          <component :is="option.icon" size="24" />
          <OwnStack spacing="1">
            <OwnType :text="option.label" variant="subtitle" />
            <OwnType :text="$t('unavailable')" />
          </OwnStack>
        </OwnStack>
      </OwnCard>
    </OwnStack>

    <AiTemplateEditor
      v-else
      :location-id="selectedLocation"
      @custom="$emit('custom', $event)"
    />
  </OwnStack>
</template>

<script>
import { isDefined } from '@vueuse/core'
import { PhSparkle, PhPaintBrushHousehold } from 'phosphor-vue'
import { mapGetters } from 'vuex'

import { LocationSelector } from '@/components/selectors'
import { TitleBlock } from '@/components/typography'
import { OwnCard, OwnStack, OwnTouchRadioGroup, OwnType, OwnRule } from '@/ui'
import { mapModel } from '@/utils/computed'

import AiTemplateEditor from './AiTemplateEditor.vue'

export default {
  name: 'TemplateSidebar',
  components: {
    AiTemplateEditor,
    LocationSelector,
    OwnCard,
    OwnRule,
    OwnStack,
    OwnTouchRadioGroup,
    OwnType,
    TitleBlock,
  },
  props: {
    overrides: { type: Object, default: () => {} },
    previewLoaded: { type: Boolean, required: true },
    templateConfig: { type: Object, required: true },
    value: { type: String, default: undefined },
  },
  data() {
    return {
      methodOptions: ['ai', 'custom'].map((value) => ({
        icon: value === 'ai' ? PhSparkle : PhPaintBrushHousehold,
        label: this.$t(`method-options.${value}`),
        value,
      })),
      selectedLocation: null,
      selectedMethod: 'ai',
    }
  },
  computed: {
    ...mapModel('selectedOption'),
    ...mapGetters({
      locations: 'core/brand/active/locations',
    }),
    availableOptions() {
      const { templateConfig, selectedLocation } = this

      return templateConfig.options.filter((option) => {
        if (!isDefined(option.requiresLocation)) return true

        return option.requiresLocation && isDefined(selectedLocation)
      })
    },
    showLocationSelector() {
      const { locations } = this

      return locations?.length !== 1
    },
    unavailableOptions() {
      const { templateConfig, selectedLocation } = this

      return templateConfig.options.filter((option) => {
        if (!selectedLocation) return option.requiresLocation
      })
    },
  },
  watch: {
    previewLoaded(newPreviewLoaded) {
      if (newPreviewLoaded) {
        this.selectedOption = undefined
        this.$emit('custom', { blocks: [], resource: undefined })
      }
    },
    selectedLocation(newLocation) {
      this.$emit('update:overrides', { locationId: newLocation })

      const { selectedOption, availableOptions, unavailableOptions } = this

      // Moving from location to all locations
      if (
        !isDefined(newLocation) &&
        unavailableOptions.find((option) => option.value === selectedOption)
      ) {
        this.selectedOption = availableOptions[0].value
      }
    },
    selectedMethod(newMethod) {
      if (newMethod === 'ai') {
        this.selectedOption = undefined
        this.$emit('custom', { blocks: [], resource: undefined })
      } else {
        this.selectedOption = 'blank'
      }
    },
  },
  created() {
    const { locations, selectedLocation } = this

    if (locations?.length === 1 && !selectedLocation) {
      this.selectedLocation = locations[0].id
    }
  },
}
</script>

<style lang="scss" scoped>
.template-sidebar {
  &__unavailable-option {
    padding: 16px;
    color: $text-color-secondary;
    cursor: not-allowed;
  }
}
</style>
