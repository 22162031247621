import { type CheckoutSettings } from '@owner/olympus-client'

import client from '../client'

/**
 * Get checkout settings for a location
 * @param {string} locationId Owner location id
 * @returns {Array} Checkout settings
 */
export const getCheckoutSettings = async (
  locationId: string
): Promise<CheckoutSettings> =>
  await client.get(`/locations/${locationId}/checkout-settings`)

/**
 * Update existing checkout setttings
 * @param {string} locationId Owner location id
 * @param {object} settingsUpdate Checkout settings changes
 */
export const updateCheckoutSettings = async (
  locationId: string,
  settingsUpdate: CheckoutSettings
): Promise<CheckoutSettings> =>
  await client.patch(
    `/locations/${locationId}/checkout-settings`,
    settingsUpdate
  )
