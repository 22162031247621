import { type ErrorObject } from '@vuelidate/core'
import { unref } from 'vue'

export const mapErrorMessage = (
  vuelidateErrors: ErrorObject[] | undefined
): string[] => {
  if (!vuelidateErrors) return []

  return vuelidateErrors.map<string>((err) => {
    return unref(err.$message)
  })
}
