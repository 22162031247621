import { formatMinorAmount, formatAmountCompact } from '@owner/currency'

import { type FormatFn } from './Format.types'

export interface CurrencyFormatterOptions {
  cents: 'show-always' | 'show-non-zero'
}

const defaultCurrencyFormatOptions: CurrencyFormatterOptions = {
  cents: 'show-always',
}

export const CurrencyFormatter: FormatFn<number> = (value) => {
  return formatMinorAmount(value)
}

export const getCurrencyFormatter =
  (
    options: CurrencyFormatterOptions = defaultCurrencyFormatOptions
  ): FormatFn<number> =>
  (value: number) => {
    if (options.cents === 'show-non-zero') {
      return formatAmountCompact(value)
    }

    return CurrencyFormatter(value)
  }
