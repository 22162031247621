import { type DeliveryConfig } from '@owner/olympus-client'

import client from '../client'

/**
 * Get delivery configurations for a location
 *
 *
 * {
 * id: 'l1iRwOBTezMh',
 * enabled: true,
 * locationId: 'j2cq4SKBPn2l',
 * brandId: 'VXhSbvz1B3MU',
 * zones: [],
 * createdAt: '2022-08-16T18:16:45.609Z',
 * updatedAt: '2022-08-16T18:16:45.609Z',
 * deletedAt: null,
 * }
 * @param {string} locationId Owner location id
 * @returns {Array} Delivery configuration
 */
export const getDeliveryConfiguration = async (
  locationId: string
): Promise<DeliveryConfig> =>
  await client.get(`/delivery/v1/locations/${locationId}/settings`)

/**
 * Update an existing delivery configuration
 * @param {string} locationId Owner location id
 * @param {object} configurationUpdate Delivery configuration changes
 */
export const updateDeliveryConfiguration = async (
  locationId: string,
  configurationUpdate: DeliveryConfig
): Promise<DeliveryConfig> =>
  await client.patch(
    `/delivery/v1/locations/${locationId}/settings`,
    configurationUpdate
  )
