import Vue from 'vue'
import en from 'vuetify/es5/locale/en'
import es from 'vuetify/es5/locale/es'
import fr from 'vuetify/es5/locale/fr'
import Vuetify from 'vuetify/lib/framework'

import store from '@/store'
import { getThemeColor } from '@/utils/theme'

Vue.use(Vuetify)

const DEFAULT_THEME = [
  'accent',
  'error',
  'info',
  'primary',
  'secondary',
].reduce((acc, tag) => Object.assign(acc, { [tag]: getThemeColor(tag) }), {})

export default new Vuetify({
  breakpoint: {
    thresholds: {
      /* eslint-disable sort-keys-fix/sort-keys-fix,vue/sort-keys -- allow for logical ordering */
      xs: 320,
      sm: 640,
      md: 1024,
      lg: 1920,
      /* eslint-enable sort-keys-fix/sort-keys-fix,vue/sort-keys */
    },
  },
  icons: {
    iconfont: 'fa',
  },
  lang: {
    current: store.getters['app/locale'],
    locales: {
      en,
      es,
      fr,
    },
  },
  theme: {
    themes: {
      dark: {
        ...DEFAULT_THEME,
        secondary: getThemeColor('white'),
      },
      light: DEFAULT_THEME,
    },
  },
})
