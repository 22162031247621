<i18n locale="en">
{
  "defaults": {
    "select": "Select one",
    "multi-select": "Select more than one"
  }
}
</i18n>

<template>
  <PreviewSheet class="select-preview" border="primary" radius="0">
    <OwnStack spacing="2">
      <OwnStack row>
        <PreviewTypographyLegacy :text="label" variant="titleSmall" />
        <PreviewTypographyLegacy
          v-if="required"
          text="*"
          variant="titleSmall"
          color="danger"
        />
      </OwnStack>
      <OwnStack class="select-preview__option-row">
        <OwnStack
          v-for="(option, index) in options"
          :key="index"
          spacing="1"
          align="center"
          row
        >
          <PreviewSheet
            class="select-preview__marker"
            :radius="type === 'select' ? '50%' : '0'"
            border="divider"
          />
          <PreviewTypographyLegacy :text="option" variant="paragraphRegular" />
        </OwnStack>
      </OwnStack>
    </OwnStack>
  </PreviewSheet>
</template>

<script>
import { mapGetters } from 'vuex'

import { PreviewSheet, PreviewTypographyLegacy } from '@/lib/builder'
import { OwnStack } from '@/ui'

export default {
  name: 'SelectPreview',
  components: {
    OwnStack,
    PreviewSheet,
    PreviewTypographyLegacy,
  },
  props: {
    content: { type: Object, required: true },

    /**
     * Override data for out of module rendering contexts
     */
    populatedData: { type: Object, default: undefined },
  },
  computed: {
    ...mapGetters({
      blockById: 'formBuilder/blockById',
    }),
    currentBlock() {
      const { content, populatedData } = this

      return populatedData ?? content
    },
    label() {
      const { currentBlock, type } = this

      return currentBlock?.params?.label || this.$t(`defaults.${type}`)
    },
    options() {
      const { currentBlock } = this

      return currentBlock?.params?.options ?? []
    },
    required() {
      const { currentBlock } = this

      return currentBlock?.params?.required
    },
    type() {
      const { currentBlock } = this

      return currentBlock?.type
    },
  },
}
</script>

<style lang="scss" scoped>
.select-preview {
  padding: 12px 40px;

  &__option-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
  }

  &__marker {
    width: 18px;
    height: 18px;
  }
}
</style>
