<i18n locale="en">
{
  "label": "Submit Button Title",
  "placeholder": "(optional)"
}
</i18n>

<template>
  <OwnStack spacing="8">
    <OwnInputContainer :label="$t('label')">
      <OwnInput v-model="submit.value" :placeholder="$t('placeholder')" />
    </OwnInputContainer>
  </OwnStack>
</template>

<script>
import set from 'lodash/set'
import { mapActions, mapGetters } from 'vuex'

import { FormBuilder } from '@/forms'
import { OwnStack, OwnInput, OwnInputContainer } from '@/ui'

export default {
  name: 'FormFooterEditor',
  components: {
    OwnInput,
    OwnInputContainer,
    OwnStack,
  },
  mixins: [
    FormBuilder({
      submit: {
        debounce: 250,
        validators: [],
      },
    }),
  ],
  computed: {
    ...mapGetters({
      currentResource: 'formBuilder/currentResource',
    }),
  },
  created() {
    const { currentResource } = this

    this.setInitialFormValues({ submit: currentResource.submit })
  },
  methods: {
    ...mapActions({
      updateResource: 'formBuilder/updateResource',
    }),
    async onFormControlUpdate(controlName, update) {
      await this.updateResource(set({}, controlName, update || null))
    },
  },
}
</script>
