<i18n locale="en">
{
  "actions": {
    "create": "Create"
  }
}
</i18n>

<template>
  <div>
    <OwnButton
      class="w-full"
      :text="$t('actions.create')"
      primary
      @click="onCreateClick"
    >
      <template #icon-right>
        <PhArrowCircleRight size="24" />
      </template>
    </OwnButton>

    <NewCampaignErrorDialog
      v-if="showErrorDialog"
      @close="showErrorDialog = false"
    />
  </div>
</template>

<script>
import { PhArrowCircleRight } from 'phosphor-vue/dist/phosphor-vue.esm'
import { mapGetters } from 'vuex'

import { OwnButton } from '@/ui'

import NewCampaignErrorDialog from './NewCampaignErrorDialog.vue'

export default {
  name: 'NewCampaignRightActions',
  components: {
    NewCampaignErrorDialog,
    OwnButton,
    PhArrowCircleRight,
  },
  data() {
    return {
      showErrorDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      currentResource: 'emailBuilder/currentResource',
    }),
  },
  methods: {
    onCreateClick() {
      const { currentResource } = this

      if (currentResource) {
        this.$emit('create')
      } else {
        this.showErrorDialog = true
      }
    },
  },
}
</script>
