import { useRouter } from 'vue-router/composables'

export interface UseOrderRouter {
  navToMainPage: () => Promise<void>
  navToDetailPage: (orderId: string) => Promise<void>
}

export const ORDER_PREFIX = 'brand.orders'

export const useOrderRouter = (): UseOrderRouter => {
  const router = useRouter()

  const navToMainPage = async (): Promise<void> => {
    await router.push({
      name: ORDER_PREFIX,
    })
  }

  const navToDetailPage = async (orderId: string): Promise<void> => {
    await router.push({
      name: `${ORDER_PREFIX}.detail`,
      params: {
        orderId,
      },
    })
  }

  return {
    navToDetailPage,
    navToMainPage,
  }
}
