import { PhTextAlignLeft } from 'phosphor-vue/dist/phosphor-vue.esm'

import { type BuilderBlockModule } from '@/lib/builder'
import i18n from '@/plugins/i18n'

import {
  type CampaignResource,
  type GiftCardSection,
  type GiftCardSectionCreate,
  type GiftCardSectionUpdate,
} from '../../types'
import { emailBlockMethods } from '../common/methods'

import { createGiftCardData } from './methods/createGiftCardData'
import GiftCardPreview from './preview/GiftCardPreview.vue'

export const GiftCardBlockModule: BuilderBlockModule<
  GiftCardSection,
  GiftCardSectionCreate,
  GiftCardSectionUpdate,
  CampaignResource
> = {
  canAdd: false,
  canDelete: false,
  canReorderDown: false,
  canReorderUp: false,
  createData: createGiftCardData,
  meta: [
    {
      icon: PhTextAlignLeft,
      key: 'gift-card',
      label: i18n.t('email-builder.blocks.gift-card'),
    },
  ],
  methods: emailBlockMethods,
  preview: GiftCardPreview,
}
