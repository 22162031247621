import { isAfter, isEqual, parseISO } from 'date-fns'

import store from '@/store'
import { checkACL, checkRole, checkScopes } from '@/utils/acl'

import { getRedirect } from './common/getRedirect'

/**
 * Check if the user is authenticated if the path requires authentication
 * @param {object} to Vue-router `to` object
 * @returns {object|undefined} a `next` object
 */
const checkAuthenticated = (to) => {
  const { meta = {} } = to
  if (meta.authenticated === undefined) return

  const isAuthenticated = store.getters['session/isAuthenticated']

  if (meta.authenticated && !isAuthenticated)
    return getRedirect(to, 'auth.login', { redirectFrom: to.fullPath })

  if (!meta.authenticated && isAuthenticated && to.name !== 'auth.subscribe')
    return getRedirect(to)
}

/**
 * Check to see if the user has the permissions to enter the current path
 * @param {object} to Vue-router `to` object
 * @returns {object|undefined} a `next` object
 */
const checkPermissions = (to) => {
  const user = store.getters['session/user']
  const scope = store.getters['session/scopes']
  const userAcl = store.getters['session/acl']

  const { meta } = to
  const { acl, roles, scopes } = meta
  const { redirect } = meta

  if (
    (roles && !checkRole(user.role, roles)) || // Check Role
    (scopes && !checkScopes(scope, scopes)) // Check Scope
  ) {
    return getRedirect(to, redirect)
  }

  if (acl) {
    const aclParams = Object.entries(acl).reduce(
      (acc, [collection, routeParam]) =>
        Object.assign(acc, { [collection]: to.params[routeParam] }),
      {}
    )
    if (!checkACL(userAcl, aclParams)) {
      return getRedirect(to, redirect)
    }
  }
}

/**
 * Check if the current session is expired or not
 * @param {object} to Vue-router `to` object
 * @returns {object|undefined} a `next` object
 */
const checkSessionExpiration = (to) => {
  const isAuthenticated = store.getters['session/isAuthenticated']
  const expiresAt = store.getters['session/expiresAt']
  const now = new Date()
  const expiresAtDate = parseISO(expiresAt)
  if (
    isAuthenticated &&
    (expiresAt
      ? isEqual(now, expiresAtDate) || isAfter(now, expiresAtDate)
      : true)
  ) {
    store.dispatch('session/logout')
    return getRedirect(to, 'auth.login')
  }
}

export default [checkAuthenticated, checkPermissions, checkSessionExpiration]
