<template>
  <AppObserver :id="appId">
    <router-view />
    <ToastManager />
  </AppObserver>
</template>
<script>
import { useFavicon } from '@vueuse/core'

import AppObserver from './components/AppObserver'
import ToastManager from './components/toasts/ToastManager'
import intercomManager from './mixins/intercomManager'
import localeHandler from './mixins/localeHandler'
import pageTitle from './mixins/pageTitle'
import sessionManager from './mixins/sessionManager'

const VITE_APP_ID = import.meta.env.VITE_APP_ID

export default {
  name: 'App',
  components: {
    AppObserver,
    ToastManager,
  },
  mixins: [intercomManager, localeHandler, pageTitle, sessionManager],
  data() {
    return {
      appId: VITE_APP_ID,
    }
  },
  created() {
    window.addEventListener('error', this.onGlobalError)
  },
  destroyed() {
    window.removeEventListener('error', this.onGlobalError)
  },
  methods: {
    onAppUpdate() {
      location.reload()
    },
    onGlobalError(event) {
      if (event.message.startsWith('Failed to load module script')) {
        this.onAppUpdate()
      }
    },
  },
  setup() {
    useFavicon(
      import.meta.env.MODE !== 'production'
        ? '/favicon/favicon-dev.ico'
        : '/favicon/favicon.ico'
    )
  },
}
</script>
