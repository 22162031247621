
{
  "title": "Add Brand",
  "actions": {
    "add": "Add Brand"
  },
  "labels": {
    "empty": "No Brands Found"
  }
}
