<i18n locale="en">
{
  "labels": {
    "date": "Date",
    "time": "Time",
    "subject": "Subject"
  }
}
</i18n>

<template>
  <OwnStack spacing="6">
    <OwnInputContainer
      v-if="editSubject"
      :label="$t('labels.subject')"
      :errors="subjectLine.status.errors"
      :max-chars="MAX_SUBJECT_LENGTH"
      :value="subjectLine.value"
    >
      <OwnInput v-model="subjectLine.value" />
    </OwnInputContainer>
    <OwnStack v-if="editSchedule">
      <OwnStack row spacing="3" align="start">
        <OwnInputContainer
          :label="$t('labels.date')"
          :errors="day.status.errors"
        >
          <DatePicker v-model="day.value" future />
        </OwnInputContainer>
        <OwnStack class="otc-editor__time-input" spacing="1">
          <OwnInputContainer
            :label="$t('labels.time')"
            :errors="time.status.errors"
          >
            <TimePicker v-model="time.value" :same-day="sameDay" />
          </OwnInputContainer>
          <OwnType :text="timezone" variant="paragraph-small" />
        </OwnStack>
      </OwnStack>
    </OwnStack>
  </OwnStack>
</template>

<script>
import { format, parse } from 'date-fns'

import { DatePicker, TimePicker } from '@/components/inputs/date-time'
import { FormBuilder, Validators } from '@/forms'
import { OwnInput, OwnInputContainer, OwnStack, OwnType } from '@/ui'
import { getLocalizedTimezone } from '@/utils/time'

const MAX_SUBJECT_LENGTH = 200

export default {
  name: 'OTCEditor',
  components: {
    DatePicker,
    OwnInput,
    OwnInputContainer,
    OwnStack,
    OwnType,
    TimePicker,
  },
  mixins: [
    FormBuilder({
      day: {
        validateOnInit: true,
        validators: [Validators.required],
      },
      subjectLine: {
        validateOnInit: true,
        validators: [
          Validators.required,
          Validators.maxLength(MAX_SUBJECT_LENGTH),
        ],
      },
      time: {
        validateOnInit: true,
        validators: [Validators.required],
      },
    }),
  ],
  props: {
    /**
     * A synced prop for the date of the campaign
     */
    date: { type: Date, default: () => new Date() },

    /**
     * A synced prop indicating form validity
     */
    disabled: { type: Boolean, default: false },

    /** Allows editing the campaign scheduled date */
    editSchedule: { type: Boolean, default: false },

    /** Allows editing the campaign subject  */
    editSubject: { type: Boolean, default: false },

    /**
     * A synced prop for the subject of the campaign
     */
    subject: { type: String, default: undefined },
  },
  data() {
    return {
      MAX_SUBJECT_LENGTH,
      timezone: getLocalizedTimezone(),
    }
  },
  computed: {
    sameDay() {
      return this.day.value === format(new Date(), 'yyyy-MM-dd')
    },
  },
  watch: {
    date: 'initialize',
    day: {
      deep: true,
      handler: 'updateScheduledDate',
    },
    isFormValid(isFormValid) {
      this.$emit('update:disabled', !isFormValid)
    },
    sameDay(sameDay, previousDateWasSameDay) {
      if (previousDateWasSameDay === sameDay) return
      this.time.value = sameDay ? format(new Date(), 'h:mm a') : '9:00 AM' // We need to prevent "Now" from being selected when the date changes
    },
    subject: 'initialize',
    subjectLine: {
      deep: true,
      handler() {
        this.$emit('update:subject', this.subjectLine.value)
      },
    },
    time: {
      deep: true,
      handler() {
        this.updateScheduledDate()
      },
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    initialize() {
      const date = this.date ?? new Date()
      this.subjectLine.value = this.subject
      this.day.value = format(date, 'yyyy-MM-dd', new Date())
      this.time.value = format(date, 'h:mm a', new Date())
    },
    updateScheduledDate() {
      const { day, time } = this

      this.$emit(
        'update:date',
        parse(`${day.value} ${time.value}`, 'yyyy-MM-dd h:mm a', new Date())
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.otc-editor {
  &__time-input {
    flex: 1;
    position: relative;
  }
}
</style>
