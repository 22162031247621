import { computed, type ComputedRef, ref, type WritableComputedRef } from 'vue'

import { ConfiguredClient } from '@/api'
import { type CoreLocation } from '@/core/store/types'
import { useMappedGetter } from '@/store'

import { type Location } from '../../types'

interface LocationCache {
  selectedLocations: ComputedRef<Location[]>
  addToCache: (locations: Location[]) => void
  loadLocations: (locationIds: string[]) => Promise<void>
}

export const useLocationCache = (
  selectedLocationIds: WritableComputedRef<string[]>
): LocationCache => {
  const brandLoadedLocations = useMappedGetter<CoreLocation[]>(
    'core/brand/active/locations'
  )

  const loadedLocations = ref<Location[]>([])

  const cachedLocations = computed(() => {
    return [
      ...brandLoadedLocations.value.map(({ paymentPastDue, ...rest }) => rest),
      ...loadedLocations.value,
    ]
  })

  const selectedLocations = computed(() => {
    return (
      selectedLocationIds.value
        // look up values in cached data
        .map((id) =>
          cachedLocations.value.find((location) => location.id === id)
        )
        // filter out the ones we didn't find / haven't loaded yet
        .filter((value) => value) as Location[]
    )
  })

  const addToCache = (locations: Location[]): void => {
    for (const location of locations) {
      loadedLocations.value.push(location)
    }
  }

  const loadLocations = async (locationIds: string[]): Promise<void> => {
    const externalRequests = []

    for (const locationId of locationIds) {
      if (
        !cachedLocations.value.find((location) => location.id === locationId)
      ) {
        externalRequests.push(
          ConfiguredClient.locations.location.getLocation({
            params: {
              locationId,
            },
          })
        )
      }
    }

    loadedLocations.value = await Promise.all(externalRequests)
  }

  return {
    addToCache,
    loadLocations,
    selectedLocations,
  }
}
