import { sanitizeUrl } from '@braintree/sanitize-url'

import { adminRouter } from '@/features/admin/router/admin.router'
import { authRouter } from '@/features/auth/router/auth.router'
import { oauthRouter } from '@/features/oauth/router/oauth.router'

import { brandRoutes } from './brandRoutes'

const VITE_ERIS_APP_URL = import.meta.env.VITE_ERIS_APP_URL

export default [
  {
    path: '/grader',
    redirect: () => {
      const sanitizedUrl = sanitizeUrl(VITE_ERIS_APP_URL)
      window.location.replace(sanitizedUrl)
      return sanitizedUrl
    },
  },
  {
    path: '/usfoods',
    redirect: () => {
      const sanitizedUrl = sanitizeUrl(`${VITE_ERIS_APP_URL}/usfoods`)
      window.location.replace(sanitizedUrl)
      return sanitizedUrl
    },
  },
  {
    children: [...brandRoutes, ...adminRouter, ...oauthRouter, ...authRouter],
    component: {
      name: 'AppRouterView',
      // eslint-disable-next-line xss/no-mixed-html
      template: '<router-view></router-view>',
    },
    name: 'app',
    path: '/',
  },
  {
    name: 'not-found',
    path: '*',
    redirect: '/',
  },
]
