import Router from 'vue-router'

import store from '@/store'
import { logError } from '@/utils/logging'

const getCurrentLocale = () => store.getters['app/locale']

const handleRouterErrors = (error) => {
  if (error.name === 'NavigationDuplicated') return
  logError(error)
}

const getNavigationMethod = (method) =>
  function (route) {
    if (route.name) {
      route.params = {
        locale: getCurrentLocale(),
        ...(route.params || {}),
      }
    }
    return method.call(this, route).catch(handleRouterErrors)
  }

const originalPush = Router.prototype.push
Router.prototype.push = getNavigationMethod(originalPush)

const originalReplace = Router.prototype.replace
Router.prototype.replace = function (route) {
  return originalReplace.call(this, route).catch(handleRouterErrors)
}
