<i18n locale="en">
{
  "errors": {
    "load": "There was a problem loading the analytics for this campaign"
  },
  "labels": {
    "sales": "Sales",
    "orders": "Orders",
    "click-rate": "Click Rate",
    "sent": "Sent",
    "delivered": "Delivered",
    "opened": "Opened",
    "bounced": "Bounced"
  }
}
</i18n>

<template>
  <OwnSkeletonLoader class="campaign-analytics" height="250">
    <OwnStack v-if="!isLoading" spacing="6">
      <OwnStack
        v-for="({ label, value }, i) in analytics"
        :key="i"
        justify="between"
        row
      >
        <OwnType :text="label" variant="subtitle" color="primary" />
        <OwnType :text="value" variant="subtitle" />
      </OwnStack>
    </OwnStack>
  </OwnSkeletonLoader>
</template>

<script>
import { ConfiguredClient } from '@/api'
import { logError } from '@/logger'
import notify from '@/mixins/notify'
import { OwnSkeletonLoader, OwnStack, OwnType } from '@/ui'
import { formatNumberValue } from '@/utils/helpers'

export default {
  name: 'CampaignAnalytics',
  components: { OwnSkeletonLoader, OwnStack, OwnType },
  mixins: [notify],
  props: {
    /** The ID of the current campaign */
    campaign: { type: String, required: true },

    /** The transport of the current campaign */
    transport: { type: String, default: null },
  },
  data() {
    return {
      analytics: [],
      isLoading: true,
    }
  },
  mounted() {
    this.loadAnalytics()
  },
  methods: {
    async loadAnalytics() {
      try {
        const { campaign: campaignId } = this
        this.isLoading = true
        const analytics =
          await ConfiguredClient.marketing.v1.campaigns.campaign.getCampaignAnalytics(
            { params: { campaignId } }
          )

        this.analytics = [
          ...(this.transport === 'email'
            ? [
                {
                  format: 'currency',
                  name: 'sales',
                  value: analytics.sales,
                },
                {
                  name: 'orders',
                  value: analytics.orders,
                },
                {
                  format: 'percentage',
                  name: 'click-rate',
                  value: analytics.clicked,
                },
              ]
            : []),
          {
            name: 'sent',
            value: analytics.sent,
          },
          {
            format: 'percentage',
            name: 'delivered',
            value: analytics.delivered,
          },
          ...(this.transport === 'email'
            ? [
                {
                  format: 'percentage',
                  name: 'opened',
                  value: analytics.opened,
                },
                {
                  format: 'percentage',
                  name: 'bounced',
                  value: analytics.bounced,
                },
              ]
            : []),
        ].map(({ format, name, value }) => ({
          label: this.$t(`labels.${name}`),
          value: formatNumberValue(value, format),
        }))
      } catch (error) {
        logError(error)
        this.$notify(this.$t('errors.load'), 'error')
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
