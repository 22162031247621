<i18n locale="en">
{
  "title": "Form Title",
  "description": "Form description goes here."
}
</i18n>

<template>
  <PreviewSheet class="form-header-preview" border="primary" radius="0">
    <OwnStack spacing="2">
      <PreviewTypographyLegacy :text="title" variant="heading2" />
      <PreviewTypographyLegacy :text="description" variant="paragraphRegular" />
    </OwnStack>
  </PreviewSheet>
</template>

<script>
import { mapGetters } from 'vuex'

import { PreviewSheet, PreviewTypographyLegacy } from '@/lib/builder'
import { OwnStack } from '@/ui'

export default {
  name: 'FormHeaderPreview',
  components: {
    OwnStack,
    PreviewSheet,
    PreviewTypographyLegacy,
  },
  props: {
    /** Override data for out of module rendering contexts */
    populatedData: { type: Object, default: undefined },
  },
  computed: {
    ...mapGetters({
      currentResource: 'formBuilder/currentResource',
    }),
    activeResource() {
      const { populatedData, currentResource } = this

      return populatedData ?? currentResource
    },
    description() {
      const { activeResource } = this

      return activeResource?.description || this.$t('description')
    },
    title() {
      const { activeResource } = this

      return activeResource?.title || this.$t('title')
    },
  },
}
</script>

<style lang="scss" scoped>
.form-header-preview {
  padding: 0 40px 24px;
}
</style>
