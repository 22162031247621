import model from './model'

export default {
  RESET_STATE: (state) => {
    Object.keys(model).forEach((k) => {
      state[k] = model[k]
    })
  },
  SET_BRAND: (state, brand) => {
    if (state.brand?.id === brand.id) {
      state.brand = brand
    } else {
      state.brand = brand
    }
  },
  SET_LOCATION: (state, location) => {
    state.locations = state.locations.map((oldLocation) =>
      oldLocation.id === location.id ? location : oldLocation
    )
  },
  SET_LOCATIONS: (state, locations) => {
    state.locations = locations
  },
  SET_LOCATION_HOURS: (state, { hours, locationId }) => {
    const locationIndex = state.locations.findIndex(
      ({ id }) => id === locationId
    )

    if (locationIndex >= 0) {
      state.locations[locationIndex].hours = hours
    }
  },
  UPDATE_LOCATION_WOFLOW: (state, { locationId, woflowIntegration }) => {
    const locationIndex = state.locations.findIndex(
      (location) => location.id === locationId
    )

    if (locationIndex >= 0) {
      state.locations[locationIndex].integrations.woflow = woflowIntegration
    }
  },
}
