
{
  "cta": "Use Coupon",
  "title": "Get {amount} OFF with code {code}",
  "subtitles": {
    "coupon": {
      "order": "On your next order.",
      "order-items": "On your next order, only for select items.",
      "order-timeframe": "On your next order, for {timeframe} only.",
      "order-items-timeframe": "On your next order, only for select items, for {timeframe} only.",
      "min": "On your next order of {min} or more.",
      "min-items": "On your next order of {min} or more, only for select items.",
      "min-timeframe": "On your next order of {min} or more, for {timeframe} only.",
      "min-items-timeframe": "On your next order of {min} or more, only for select items, for {timeframe} only."
    }
  },
  "timeframes": {
    "today": "today",
    "week": "this week",
    "month": "this month",
    "year": "this year"
  }
}
