import { PhLeaf } from 'phosphor-vue/dist/phosphor-vue.esm'

import { type BuilderBlockModule } from '@/lib/builder'
import i18n from '@/plugins/i18n'

import {
  type LocationsSectionCreate,
  type LocationsSection,
  type WebsiteResource,
  type LocationsSectionUpdate,
} from '../../WebsiteBuilder.types'
import { websiteBlockMethods } from '../common/methods'

import { createLocationsData } from './methods/createLocationsData'
import LocationsPreview from './preview/LocationsPreview.vue'

export const LocationsBlockModule: BuilderBlockModule<
  LocationsSection,
  LocationsSectionCreate,
  LocationsSectionUpdate,
  WebsiteResource
> = {
  canAdd: false,
  canDelete: false,
  canReorderDown: false,
  canReorderUp: false,
  createData: createLocationsData,
  meta: [
    {
      icon: PhLeaf,
      key: 'locations',
      label: i18n.t('website-builder.blocks.locations'),
    },
  ],
  methods: websiteBlockMethods,
  preview: LocationsPreview,
}
