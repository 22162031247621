import store from '@/store'
import { checkRole } from '@/utils/acl'

/**
 * If the path doesn't match a location view, clear the location store
 * @param {object} to Vue-router `to` object
 */
const clearLocation = (to) => {
  if (!to.params.locationId && store.getters['location/location'])
    store.dispatch('location/clearLocation')
}

/**
 * If the path doesn't match a location view, clear the location store
 * @param {object} to Vue-router `to` object
 */
const clearBrand = (to) => {
  if (!checkRole(store.getters['session/role'], 'pb:*')) return
  if (!to.params.brandId && store.getters['brand/brand']) {
    store.dispatch('brand/clearBrand')
  }
}

export default [clearLocation, clearBrand]
