<i18n locale="en">
{
  "placeholder": "Video ID",
  "prefix": "https://youtu.be/"
}
</i18n>
<template>
  <OwnInput
    v-model="displayValue"
    :accessibility-id="accessibilityId"
    :disabled="disabled"
    :error="error"
    :placeholder="$t('placeholder')"
    :start-text="$t('prefix')"
  />
</template>
<script>
import { OwnInput } from '@/ui'
import { mapModel } from '@/utils/computed'

export default {
  name: 'YoutubeInput',
  components: { OwnInput },
  props: {
    /**
     * Accessibility ID
     */
    accessibilityId: { type: String, default: undefined },

    /**
     * Is input disabled
     */
    disabled: { type: Boolean, default: false },

    /**
     * Has Error
     */
    error: { type: Boolean, default: false },

    /**
     * Model Value
     * @model
     */
    value: { type: String, default: undefined },
  },
  computed: {
    ...mapModel('dataValue'),
    displayValue: {
      get() {
        const { dataValue } = this

        return dataValue
      },
      set(newValue) {
        const id = this.processInputToYoutubeId(newValue)

        this.dataValue = id
      },
    },
  },
  methods: {
    processInputToYoutubeId(inputText) {
      let id = undefined

      const urlType = inputText.split('?')

      if (urlType.length > 1) {
        // Contains Query Params
        const queryParams = urlType[1].split('&')

        const vQueryParam = queryParams.find((param) => param.startsWith('v='))

        if (vQueryParam) {
          id = vQueryParam.split('=')[1]
        } else {
          const splitUrlType = urlType[0].split('/')
          id = splitUrlType[splitUrlType.length - 1]
        }
      } else {
        // Embed Link
        const splitUrlType = urlType[0].split('/')
        id = splitUrlType[splitUrlType.length - 1]
      }

      return id
    },
  },
}
</script>
