import { BuilderExtension, type BuilderState } from '@/lib/builder'
import { Store } from '@/lib/store'

import { type Form, type FormSection } from '../../types/FormBuilder.types'

export interface FormBuilderState {
  // this is only here because eslint won't let me define an empty interface...
  pages?: string[]
}

const formBuilder = Store.extend<
  FormBuilderState,
  BuilderState<Form, FormSection>
>(
  {
    actions: {},
    getters: {},
    mutations: {},
    state: {},
  },
  BuilderExtension({
    moduleName: 'formBuilder',
  })
)

const formBuilderModule = Store.createModule(formBuilder, true)

export default formBuilderModule
