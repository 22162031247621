<i18n locale="en">
{
  "title": "You have unsaved changes",
  "message": "If you leave this page you will lose your unsaved changes, are you sure you want to discard changes?",
  "actions": {
    "continue-editing": "Continue Editing",
    "discard-changes": "Discard unsaved changes"
  }
}
</i18n>

<template>
  <OwnDialog v-model="isVisible" @cancel="onCancel">
    <template #heading="{ close }">
      <OwnDialogHeading :title="$t('title')" @cancel="onCancel(close)" />
    </template>
    <template #default>
      <OwnDialogContent scroll>
        <p
          class="text-paragraph text-color-secondary"
          v-text="$t('message')"
        ></p>
      </OwnDialogContent>
    </template>
    <template #footer="{ close }">
      <OwnDialogFooter>
        <OwnButton
          v-if="isDesktop"
          :text="$t('actions.continue-editing')"
          @click="onCancel(close)"
        />
        <OwnButton
          :text="$t('actions.discard-changes')"
          danger
          @click="onConfirmDiscard(close)"
        />
      </OwnDialogFooter>
    </template>
  </OwnDialog>
</template>

<script>
import breakpoints from '@/mixins/breakpoints'
import {
  OwnButton,
  OwnDialog,
  OwnDialogFooter,
  OwnDialogContent,
  OwnDialogHeading,
} from '@/ui'
import { mapModel } from '@/utils/computed'

export default {
  name: 'UnsavedChangesDialog',
  components: {
    OwnButton,
    OwnDialog,
    OwnDialogContent,
    OwnDialogFooter,
    OwnDialogHeading,
  },
  mixins: [breakpoints],
  props: {
    /** Show Modal */
    value: { type: Boolean, default: false },
  },
  computed: {
    ...mapModel('isVisible'),
    isDesktop() {
      return this.$breakpoints.mdAndUp
    },
  },
  methods: {
    onCancel(close) {
      this.$emit('cancel')
      if (close) {
        close()
      }
    },
    onConfirmDiscard(close) {
      this.$emit('discard')
      if (close) {
        close()
      }
    },
  },
}
</script>
