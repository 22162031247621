import { type RouteConfig } from 'vue-router'

import { ORDER_PREFIX } from './useOrderRouter'

export const ordersRouter: RouteConfig[] = [
  {
    component: async () => import('../views/OrdersMainView.vue'),
    meta: {
      scopes: ['orders:read'],
      title: 'restaurant.orders',
    },
    name: ORDER_PREFIX,
    path: 'orders',
  },
  {
    component: async () => import('../views/OrdersDetailView.vue'),
    meta: {
      scopes: ['orders:read'],
    },
    name: `${ORDER_PREFIX}.detail`,
    path: 'orders/:orderId',
    props: true,
  },
]
