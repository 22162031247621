import { type FormatFn } from './Format.types'

/**
 * Formats a percentage according to the locale of the user without any rounding or decimal place fixing.  Different
 * countries will append or prepend the percent symbol, while other countries don't use it at all.  Output will have the
 * same number of decimal places as the input. To control/round the number of digits after the decimal, use toFixed()
 * first.
 * @param value A literal percent value (e.g. 1 == 1%, 50 == 50%).  If you have a value ratio percent (e.g. 1 == 100%,
 * .5 == 50%), multiply that number by 100 first.
 * @returns A formatted percentage string.
 */
export const PercentFormatter: FormatFn<number> = (value: number): string => {
  // Number.toLocaleString forces us to round or zero-pad when formatting as a percentage.  To get around this and
  // maintain localization, use Number.toLocaleString to create what is essentially a template of a static localized
  // percentage, then replace the static value with the separately localized numeric value.
  const template = (0.01).toLocaleString(undefined, { style: 'percent' })
  return template.replace('1', value.toLocaleString())
}
