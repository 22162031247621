<i18n locale="en">
{
  "labels": {
    "label": "Title",
    "options": "Options",
    "choices": "Choices",
    "required": "Required",
    "add": "Add option"
  }
}
</i18n>

<template>
  <OwnStack class="select-editor" spacing="8">
    <OwnInputContainer
      :label="$t('labels.label')"
      :errors="label.status.errors"
    >
      <OwnInput v-model="label.value" :placeholder="$t('labels.label')" />
    </OwnInputContainer>
    <OwnStack spacing="3">
      <OwnType
        :text="$t('labels.options')"
        color="primary"
        variant="subtitle"
      />
      <OwnToggle
        v-model="required.value"
        :label="$t('labels.required')"
        control
      />
    </OwnStack>
    <OwnStack spacing="3">
      <OwnType
        :text="$t('labels.choices')"
        color="primary"
        variant="subtitle"
      />
      <Reorderable
        v-slot="{ item, index }"
        v-model="options.value"
        item-key="option"
        class="space-y-3"
        @reorder="onReorder"
      >
        <OwnOption :key="index" el="div">
          <OwnStack align="center" justify="between" row>
            <OwnStack align="center" spacing="3" row>
              <OwnStack
                class="select-editor__drag-handle drag-handle"
                align="center"
              >
                <OwnIcon
                  :icon="PhDotsSixVertical"
                  class="text-color-secondary"
                  size="sm"
                />
              </OwnStack>
              <OwnType
                class="select-editor__option-text"
                :text="item"
                variant="subtitle"
              />
            </OwnStack>
            <button @click="deleteOption(index)">
              <OwnIcon :icon="PhTrash" class="text-color-secondary" size="sm" />
            </button>
          </OwnStack>
        </OwnOption>
      </Reorderable>
      <OwnOption class="select-editor__new-option" el="div">
        <OwnInput
          v-model="newOption"
          :placeholder="$t('labels.add')"
          @keyup.enter="commitNew"
        >
          <template #prepend>
            <OwnIcon :icon="PhPlusCircle" size="sm" />
          </template>
          <template #append>
            <button v-if="newOption" @click="commitNew">
              <OwnIcon :icon="PhCheckCircle" color="bluetiful-400" size="sm" />
            </button>
          </template>
        </OwnInput>
      </OwnOption>
    </OwnStack>
  </OwnStack>
</template>

<script>
import {
  PhDotsSixVertical,
  PhTrash,
  PhPlusCircle,
  PhCheckCircle,
} from 'phosphor-vue/dist/phosphor-vue.esm'
import { mapGetters } from 'vuex'

import { FormBuilder } from '@/forms'
import {
  OwnStack,
  OwnInputContainer,
  OwnInput,
  OwnToggle,
  OwnIcon,
  OwnType,
  OwnOption,
  Reorderable,
} from '@/ui'
import { reorder } from '@/utils/helpers'

import { SelectCommands } from '../commands/SelectCommands'

export default {
  name: 'SelectEditor',
  components: {
    OwnIcon,
    OwnInput,
    OwnInputContainer,
    OwnOption,
    OwnStack,
    OwnToggle,
    OwnType,
    Reorderable,
  },
  mixins: [
    FormBuilder({
      label: {
        debounce: 250,
        validators: [],
      },
      options: {
        debounce: 250,
        initialValue: [],
        validators: [],
      },
      required: {
        initialValue: false,
        validators: [],
      },
    }),
  ],
  props: {
    /**
     * Id getting passed to the editor component
     */
    id: { type: String, required: true },
  },
  data() {
    return {
      PhCheckCircle,
      PhDotsSixVertical,
      PhPlusCircle,
      PhTrash,
      editing: null,
      newOption: '',
      sectionData: undefined,
    }
  },
  computed: {
    ...mapGetters({
      blockById: 'formBuilder/blockById',
    }),
    currentBlockParams() {
      const { id } = this

      return this.blockById(id)?.params
    },
  },
  created() {
    const { currentBlockParams } = this

    this.setInitialFormValues(currentBlockParams)
  },
  methods: {
    addNew() {
      this.editing = 'new'
      this.newOption = ''
    },
    commitNew() {
      const { newOption, formValues } = this

      if (formValues.options.includes(newOption)) {
        this.newOption = ''
        return
      }

      if (newOption) {
        this.options.value = [...formValues.options, newOption]
        this.newOption = ''
      }
    },
    deleteOption(index) {
      const { formValues } = this
      const { options } = formValues

      options.splice(index, 1)
      this.options.value = [...options]
    },
    async onFormControlUpdate(controlName, update) {
      const { id } = this

      await SelectCommands.updateBlock({
        targetId: id,
        update: { params: { [controlName]: update } },
      })
    },
    async onReorder({ newIndex, oldIndex }) {
      const { formValues } = this

      const newOptions = reorder(formValues.options, oldIndex, newIndex)

      await this.onFormControlUpdate('options', newOptions)
    },
  },
}
</script>
<style lang="scss" scoped>
.select-editor {
  &__new-option {
    padding: 0;
    border: none;
  }

  &__drag-handle {
    cursor: grab;
  }

  &__option-text {
    user-select: none;
  }
}
</style>
