<template>
  <OwnStack spacing="2" row>
    <DatePicker v-model="date" />
    <TimePicker v-model="time" />
  </OwnStack>
</template>
<script>
import format from 'date-fns/format'
import getDate from 'date-fns/getDate'
import getHours from 'date-fns/getHours'
import getMinutes from 'date-fns/getMinutes'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'
import parse from 'date-fns/parse'
import set from 'date-fns/set'

import { OwnStack } from '@/ui'
import { mapModel } from '@/utils/computed'

import DatePicker from './DatePicker'
import TimePicker from './TimePicker'

export default {
  name: 'DateTimePicker',
  components: {
    DatePicker,
    OwnStack,
    TimePicker,
  },
  props: {
    value: { type: Date, required: true },
  },
  computed: {
    ...mapModel('datetime'),
    date: {
      get() {
        const { datetime } = this

        return format(datetime, 'yyyy-MM-dd')
      },
      set(newDateStr) {
        const { datetime } = this

        const newDate = parse(newDateStr, 'yyyy-MM-dd', datetime)

        this.datetime = set(datetime, {
          date: getDate(newDate),
          month: getMonth(newDate),
          year: getYear(newDate),
        })
      },
    },
    time: {
      get() {
        const { datetime } = this

        return format(datetime, 'h:mm a')
      },
      set(newTimeStr) {
        const { datetime } = this

        const newTime = parse(newTimeStr, 'h:mm a', datetime)

        this.datetime = set(datetime, {
          hours: getHours(newTime),
          minutes: getMinutes(newTime),
        })
      },
    },
  },
}
</script>
