<template>
  <OwnStack class="landing-hero-layout-cover-center">
    <OwnStack class="landing-hero-layout-cover-center__container">
      <LandingHeroBackground
        class="landing-hero-layout-cover-center__background"
        :carousel-assets="carouselAssets"
      />
      <PreviewSheet
        class="landing-hero-layout-cover-center__content"
        radius="large"
        background="translucent"
      >
        <LandingHeroContentGroup :id="id" />
      </PreviewSheet>
    </OwnStack>
  </OwnStack>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useWebsiteSection } from '@/features/website-builder/builder/common/useWebsiteSection'
import { PreviewSheet } from '@/lib/builder'
import { OwnStack } from '@/ui'

import LandingHeroBackground from './LandingHeroBackground.vue'
import LandingHeroContentGroup from './LandingHeroContentGroup.vue'

const props = defineProps<{
  id: string
}>()

const activeBlock = useWebsiteSection(props.id)

const carouselAssets = computed(() =>
  activeBlock.value?.params && 'carouselAssets' in activeBlock.value?.params
    ? activeBlock.value?.params?.carouselAssets
    : undefined
)
</script>

<style lang="scss" scoped>
.landing-hero-layout-cover-center {
  position: relative;
  min-height: 800px;
  padding: 0 8px 8px;
  display: flex;
  overflow: hidden;

  &__container {
    flex: 1;
    border-radius: 32px;
    position: relative;
    overflow: hidden;
    padding: 8px;
  }

  &__background {
    position: absolute;
    inset: 0;
  }

  &__content {
    position: relative;
    z-index: 3;
    padding: 80px;
    max-width: 672px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
