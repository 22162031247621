import {
  PhMapPin,
  PhCalendarBlank,
  PhDeviceMobile,
  PhUserCircle,
  PhEnvelopeSimple,
} from 'phosphor-vue/dist/phosphor-vue.esm'

import { type BuilderBlockModule } from '@/lib/builder'
import i18n from '@/plugins/i18n'

import type {
  Form,
  FormattedInputSection,
  FormattedInputSectionCreate,
  FormattedInputSectionUpdate,
} from '../../../types/FormBuilder.types'
import { formBlockMethods } from '../common/methods'

import FormattedInputEditor from './editor/FormattedInputEditor.vue'
import { createFormattedInputData } from './methods/createFormattedInputData'
import FormattedInputPreview from './preview/FormattedInputPreview.vue'

export const FormattedInputBlockModule: BuilderBlockModule<
  FormattedInputSection,
  FormattedInputSectionCreate,
  FormattedInputSectionUpdate,
  Form
> = {
  createData: createFormattedInputData,
  editor: FormattedInputEditor,
  meta: [
    {
      icon: PhMapPin,
      key: 'address',
      label: i18n.t('form-builder.blocks.address'),
      sort: 6,
    },
    {
      icon: PhCalendarBlank,
      key: 'date',
      label: i18n.t('form-builder.blocks.date'),
      sort: 8,
    },
    {
      icon: PhDeviceMobile,
      key: 'phone',
      label: i18n.t('form-builder.blocks.phone'),
      sort: 4,
    },
    {
      icon: PhUserCircle,
      key: 'name',
      label: i18n.t('form-builder.blocks.name'),
      sort: 0,
    },
    {
      icon: PhEnvelopeSimple,
      key: 'email',
      label: i18n.t('form-builder.blocks.email'),
      sort: 2,
    },
  ],
  methods: formBlockMethods,
  preview: FormattedInputPreview,
}
