<template>
  <OwnInput
    v-model="displayValue"
    start-text="$"
    :accessibility-id="accessibilityId"
    :disabled="disabled"
    :error="error"
    :placeholder="placeholder"
    :borderless="borderless"
  >
    <template #append>
      <p
        v-if="previewPrice"
        class="text-button slate-300"
        v-text="previewPrice"
      ></p>
    </template>
  </OwnInput>
</template>

<script>
import { CurrencyBN, formatMinorAmount } from '@owner/currency'

import { OwnInput } from '@/ui'
import { mapModel } from '@/utils/computed'

export default {
  name: 'CurrencyInput',
  components: {
    OwnInput,
  },
  props: {
    /**
     * Accessibility ID
     */
    accessibilityId: { type: String, default: undefined },

    /**
     * Allow null value
     */
    allowNull: { type: Boolean, default: false },

    /**
     * Base Price Value
     */
    basePrice: { type: Number, default: undefined },

    borderless: { type: Boolean, default: false },

    /**
     * Is input disabled
     */
    disabled: { type: Boolean, default: false },

    /**
     * Has Error
     */
    error: { type: Boolean, default: false },

    /**
     * Placeholder
     */
    placeholder: { type: String, default: undefined },

    /**
     * Model Value
     * @model
     */
    value: { type: Number, default: undefined },
  },
  data() {
    return {
      displayValue: undefined,
    }
  },
  computed: {
    ...mapModel('dataValue'),
    previewPrice() {
      const { basePrice, dataValue } = this

      if (basePrice && dataValue) {
        return formatMinorAmount(new CurrencyBN(dataValue).plus(basePrice))
      }

      return undefined
    },
  },
  watch: {
    dataValue(newValue, oldValue) {
      this.syncValues(newValue, oldValue)
    },
    displayValue(newValue, oldValue) {
      this.syncValues(newValue, oldValue)
    },
  },
  created() {
    this.displayValue = this.toDisplayValue(this.dataValue)
  },
  methods: {
    getDefaultValue() {
      const { allowNull } = this

      return allowNull ? null : '0'
    },
    normalizeCurrencyValue(currencyValue) {
      if (currencyValue !== null && currencyValue !== undefined) {
        let onlyNumeric = currencyValue.toString().replace(/\D/g, '')

        while (onlyNumeric.charAt(0) === '0') {
          onlyNumeric = onlyNumeric.substring(1)
        }

        if (onlyNumeric.length > 3) {
          return onlyNumeric
        } else {
          const numZerosToAdd = 3 - onlyNumeric.length

          return `${new Array(numZerosToAdd + 1).join('0')}${onlyNumeric}`
        }
      }

      return null
    },
    syncValues(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.displayValue = this.toDisplayValue(newValue)
        this.dataValue = this.toIntValue(newValue)
      }
    },
    toDisplayValue(currencyValue) {
      if (currencyValue !== null && currencyValue !== undefined) {
        const normalizedValue = this.normalizeCurrencyValue(currencyValue)

        if (normalizedValue.length < 2) {
          return `0.${normalizedValue}`
        } else {
          const insertIndex = normalizedValue.length - 2
          return (
            normalizedValue.slice(0, insertIndex) +
            '.' +
            normalizedValue.slice(insertIndex)
          )
        }
      }

      return this.getDefaultValue()
    },
    toIntValue(currencyValue) {
      if (currencyValue !== null && currencyValue !== undefined) {
        const normalizedValue = this.normalizeCurrencyValue(currencyValue)
        return parseInt(normalizedValue, 10)
      }

      return null
    },
  },
}
</script>
