import { PhProjectorScreen } from 'phosphor-vue/dist/phosphor-vue.esm'

import { type BuilderBlockModule } from '@/lib/builder'
import i18n from '@/plugins/i18n'

import {
  type WebsiteResource,
  type HeroSectionCreate,
  type HeroSection,
  type HeroSectionUpdate,
} from '../../WebsiteBuilder.types'
import { websiteBlockMethods } from '../common/methods'

import HeroEditor from './editor/HeroEditor.vue'
import { createHeroData } from './methods/createHeroData'
import HeroPreview from './preview/HeroPreview.vue'

export const HeroBlockModule: BuilderBlockModule<
  HeroSection,
  HeroSectionCreate,
  HeroSectionUpdate,
  WebsiteResource
> = {
  canAdd: false,
  canDelete: ({ index }) => index !== 0,
  canReorderDown: ({ index }) => index !== 0,
  canReorderUp: ({ index }) => index !== 0,
  createData: createHeroData,
  editor: HeroEditor,
  meta: [
    {
      icon: PhProjectorScreen,
      key: 'hero',
      label: i18n.t('website-builder.blocks.hero'),
      sort: 0,
    },
  ],
  methods: websiteBlockMethods,
  preview: HeroPreview,
}
