<i18n locale="en">
{
  "map": "Google Map",
  "directions": "Get Directions",
  "address": "Address",
  "contacts": "Contacts",
  "order": "Order Now",
  "see-hours": "See hours",
  "open-until": "Open until xx:xx"
}
</i18n>
<template>
  <PreviewSheet class="info-sheet" radius="medium" background="secondary">
    <OwnStack spacing="2" row>
      <PreviewSheet class="info-sheet__map" background="divider">
        <OwnStack spacing="4" align="center">
          <PreviewIcon :icon="PhMapPin" color="secondary" size="48" />
          <PreviewTypography
            :text="$t('map')"
            variant="heading3"
            color="secondary"
          />
        </OwnStack>
      </PreviewSheet>
      <OwnStack class="info-sheet__info">
        <OwnStack align="center" justify="between" row>
          <OwnStack spacing="1">
            <PreviewTypography
              :text="brand.name"
              variant="titleSmall"
              color="secondary"
            />
            <PreviewTypography
              :text="cityState"
              variant="titleSmall"
              color="primary"
            />
          </OwnStack>
          <PreviewButton :title="$t('directions')" variant="secondary" caret />
        </OwnStack>
        <OwnStack spacing="8">
          <OwnStack spacing="20" row>
            <OwnStack v-if="address" spacing="2">
              <PreviewTypography
                :text="$t('address')"
                variant="paragraphSmall"
                color="secondary"
              />
              <OwnStack spacing="0.5">
                <PreviewTypography
                  :text="address.street"
                  variant="paragraphSmall"
                  color="primary"
                />
                <PreviewTypography
                  v-if="address.unit"
                  :text="address.unit"
                  variant="paragraphSmall"
                  color="primary"
                />
                <PreviewTypography
                  :text="`${address.city}, ${address.state} ${address.zip}`"
                  variant="paragraphSmall"
                  color="primary"
                />
              </OwnStack>
            </OwnStack>
            <OwnStack
              v-if="location.contact.phone || location.contact.email"
              spacing="2"
            >
              <PreviewTypography
                :text="$t('contacts')"
                variant="paragraphSmall"
                color="secondary"
              />
              <OwnStack spacing="0.5">
                <PreviewTypography
                  v-if="location.contact.phone"
                  :text="formatPhoneNumber(location.contact.phone)"
                  variant="paragraphSmall"
                  color="primary"
                />
                <PreviewTypography
                  v-if="location.contact.email"
                  :text="location.contact.email"
                  variant="paragraphSmall"
                  color="primary"
                />
              </OwnStack>
            </OwnStack>
          </OwnStack>
          <PreviewHr />
          <OwnStack align="center" justify="between" row>
            <OwnStack spacing="4" align="center" row>
              <PreviewTypography
                class="info-sheet__nowrap"
                :text="$t('open-until')"
                color="primary"
                variant="paragraphSmall"
              />
              <OwnStack spacing="1" align="center" row>
                <PreviewTypography
                  :text="$t('see-hours')"
                  variant="paragraphSmall"
                  color="secondary"
                />
                <PreviewIcon :icon="PhCaretRight" color="secondary" size="12" />
              </OwnStack>
            </OwnStack>
            <PreviewButton :title="$t('order')" caret />
          </OwnStack>
        </OwnStack>
      </OwnStack>
    </OwnStack>
  </PreviewSheet>
</template>
<script lang="ts" setup>
import { PhMapPin, PhCaretRight } from 'phosphor-vue/dist/phosphor-vue.esm'
import { computed } from 'vue'

import { Brand, Location } from '@/core'
import {
  PreviewButton,
  PreviewHr,
  PreviewIcon,
  PreviewSheet,
  PreviewTypography,
} from '@/lib/builder'
import { useMappedGetter } from '@/store'
import { OwnStack } from '@/ui'
import { formatPhoneNumber } from '@/utils/phone'

const props = defineProps<{ location: Location }>()

const brand = useMappedGetter<Brand>('core/brand/active/*')

const address = computed(() => props.location.address)

const cityState = computed(() => {
  if (address.value) {
    const { city, state } = address.value
    return `${city}, ${state}`
  }

  return ''
})
</script>
<style lang="scss" scoped>
.info-sheet {
  padding: 8px;

  &__info {
    padding: 24px;
    width: 100%;
    gap: 106px;
  }

  &__map {
    width: 450px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__nowrap {
    white-space: nowrap;
  }
}
</style>
