import { PhCalendarBlank } from 'phosphor-vue/dist/phosphor-vue.esm'

import { type BuilderBlockModule } from '@/lib/builder'
import i18n from '@/plugins/i18n'

import {
  type WebsiteResource,
  type EventCalendarSection,
  type EventCalendarSectionCreate,
  type EventCalendarSectionUpdate,
} from '../../WebsiteBuilder.types'
import { websiteBlockMethods } from '../common/methods'

import { createEventCalendarData } from './methods/createEventCalendarData'
import EventCalendarPreview from './preview/legacy/EventCalendarPreviewLegacy.vue'

export const EventCalendarModule: BuilderBlockModule<
  EventCalendarSection,
  EventCalendarSectionCreate,
  EventCalendarSectionUpdate,
  WebsiteResource
> = {
  canDelete: (ctx) => {
    const isEventsPage =
      ctx.resource.path.type === 'reserved' &&
      ctx.resource.path.params.page === 'events'

    // Outside of the events page, we want to always allow deleting an event block
    if (!isEventsPage) {
      return true
    }

    // When we delete a block, we don't delete it from ctx.blocks, but we delete it from the ctx.resource.sections
    const nonDeletedBlocks = ctx.resource.sections.map((sectionId) =>
      ctx.blocks.find((block) => block.id === sectionId)
    )

    const calendarEvents = nonDeletedBlocks.filter(
      (block) => block && block.type === 'event-calendar'
    )

    // In an events page, we want to allow deleting an event block if there is more than one (we want an events page to have at least one event block)
    return calendarEvents.length > 1
  },
  createData: createEventCalendarData,
  meta: [
    {
      icon: PhCalendarBlank,
      key: 'event-calendar',
      label: i18n.t('website-builder.blocks.event-calendar'),
    },
  ],
  methods: websiteBlockMethods,
  preview: EventCalendarPreview,
}
