import { timeEn } from '@/components/time/translations'
import { coreEn } from '@/core/translations'
import { authEn } from '@/features/auth/translations'
import { formBuilderEn } from '@/features/form-builder/translations'
import {
  emailBuilderEn,
  textBuilderEn,
} from '@/features/marketing/translations'
import { menuEn } from '@/features/menu/translations'
import { metricsEn } from '@/features/metrics/translations'
import { ordersEn } from '@/features/orders/translations'
import { settingsEn } from '@/features/settings/translations'
import { setupEn } from '@/features/setup/translations'
import { websiteBuilderEn } from '@/features/website-builder/translations'

export default {
  analytics: {
    summary: {
      error: 'Error loading {source}. Please refresh and try again.',
    },
    titles: {
      attribution: 'Order Attribution',
      customers: 'Customer Data',
      'item-sales': 'Item Sales',
      summary: 'Summary',
      today: "Today's Overview",
      'total-sales': 'Total Sales',
    },
  },
  auth: authEn,
  collections: {
    application: 'Application | Applications',
    brand: 'Brand | Brands',
    category: 'Category | Categories',
    coupon: 'Coupon | Coupons',
    domain: 'Domain | Domains',
    event: 'Event | Events',
    item: 'Item | Items',
    location: 'Location | Locations',
    menu: 'Menu | Menus',
    modifier: 'Modifier | Modifiers',
    'modifier-set': 'Modifier Set | Modifier Sets',
    order: 'Order | Orders',
    review: 'Review | Reviews',
    throttle: 'Throttle | Throttles',
    user: 'User | Users',
  },
  'collections-with-article': {
    brand: 'A brand',
    category: 'A category',
    discount: 'A discount',
    item: 'An item',
    location: 'A location',
    menu: 'A menu',
    modifier: 'A modifier',
    'modifier-set': 'A modifier set',
    subcategory: 'A subcategory',
    tax: 'A tax',
    variation: 'A variation',
    'variation-group': 'A variation Group',
  },
  copy: {
    error: 'Unable to copy to clipboard',
    success: 'Copied value to clipboard',
  },
  core: coreEn,
  customers: {
    filters: {
      subscribed: 'Subscribed',
    },
  },
  days: {
    friday: 'Friday',
    monday: 'Monday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    thursday: 'Thursday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
  },
  'email-builder': emailBuilderEn,
  'epoch-types': {
    days: 'Daily',
    hours: 'Hourly',
    months: 'Monthly',
    weeks: 'Weekly',
  },
  errors: {
    csv: 'Error generating CSV file. Please try again later.',
    generic: 'Oops! Something went wrong. Try again later.',
  },
  'form-builder': formBuilderEn,
  formatters: {
    'date-time': {
      today: 'Today at {time}',
      tomorrow: 'Tomorrow at {time}',
      yesterday: 'Yesterday at {time}',
    },
  },
  general: {
    and: 'and',
    'are-you-sure': 'Are you sure?',
    cancel: 'Cancel',
    close: 'Close',
    confirm: 'Confirm',
    delete: 'Delete',
    done: 'Done',
    download: 'Download',
    'download-csv': 'Download as CSV',
    loading: 'Loading...',
    'no-items-found': 'No Items Found',
    none: 'None',
    or: 'or',
    refresh: 'Refresh',
    save: 'Save',
    search: 'Search',
    select: 'Select',
    update: 'Update',
    yes: 'Yes',
  },
  menu: menuEn,
  metrics: metricsEn,
  nav: {
    action: {
      locations: 'Locations',
      loyalty: 'Loyalty',
      orders: 'Orders',
      profile: 'Profile',
    },
    partner: {
      catering: 'Catering',
      facebook: 'Facebook',
      'gift-cards': 'Gift Cards',
      instagram: 'Instagram',
      olo: 'Order Online',
      reservations: 'Reservations',
    },
  },
  orders: {
    ...ordersEn,
    filters: {
      status: 'Status',
      'status-options': {
        cancelled: 'Cancelled',
        claimed: 'Claimed',
        completed: 'Completed',
        unclaimed: 'Unclaimed',
        voided: 'Voided',
      },
      type: 'Order Type',
      'type-options': {
        delivery: 'Delivery',
        pickup: 'Pickup',
      },
    },
  },
  reviews: {
    filters: {
      stars: 'Stars',
      'stars-options': {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
      },
    },
    item: {
      unknown: 'Unknown',
    },
  },
  settings: settingsEn,
  setup: setupEn,
  'text-builder': textBuilderEn,
  time: timeEn,
  topbar: {
    'search-short': 'Locations',
  },
  'website-builder': websiteBuilderEn,
}
