<i18n locale="en">
{
  "header": "\"{title}\" Submissions",
  "subheader": "View the submissions people have submitted to your form.",
  "view-form": "View Form",
  "export": "Export Submissions",
  "export-error": "Could not export submissions."
}
</i18n>
<template>
  <OwnScrollContainer>
    <DefaultPageLayout
      :title="header"
      :subtitle="$t('subheader')"
      :max-width="1040"
    >
      <template #actions>
        <OwnStack spacing="3" row shrink>
          <OwnButton :text="$t('view-form')" @click="onViewForm" />
          <OwnButton
            :text="$t('export')"
            :processing="exporting"
            primary
            @click="onExportSubmissions"
          />
        </OwnStack>
      </template>
      <FormBuilderSubmissionsTable v-if="currentResource" />
    </DefaultPageLayout>
  </OwnScrollContainer>
</template>
<script>
import { mapGetters } from 'vuex'

import { ConfiguredClient } from '@/api'
import { DefaultPageLayout } from '@/components/layouts'
import { logError } from '@/logger'
import notify from '@/mixins/notify'
import { OwnStack, OwnButton, OwnScrollContainer } from '@/ui'
import { download, toRawLocation } from '@/utils'

import { formBuilderRouteManager } from '../../router/formBuilderModuleRouter'

import FormBuilderSubmissionsTable from './submissions-table/FormBuilderSubmissionsTable.vue'

export default {
  name: 'FormBuilderSubmissionContent',
  components: {
    DefaultPageLayout,
    FormBuilderSubmissionsTable,
    OwnButton,
    OwnScrollContainer,
    OwnStack,
  },
  mixins: [notify],
  data() {
    return {
      exporting: false,
    }
  },
  computed: {
    ...mapGetters({
      currentResource: 'formBuilder/currentResource',
    }),
    header() {
      const { currentResource } = this

      return currentResource
        ? this.$t('header', { title: currentResource.title })
        : undefined
    },
  },
  methods: {
    async onExportSubmissions() {
      const { currentResource } = this

      try {
        this.exporting = true
        const { downloadUrl } =
          await ConfiguredClient.forms.v1.forms.form.exportSubmissions({
            params: { formId: currentResource.id },
          })

        download(downloadUrl, `${currentResource.id}.csv`)
      } catch (error) {
        logError(error)
        this.$notify(this.$t('export-error'), 'error')
      } finally {
        this.exporting = false
      }
    },
    async onViewForm() {
      const { currentResource } = this

      const editRoute = await formBuilderRouteManager.editResourcePage(
        currentResource.id,
        toRawLocation(this.$route)
      )

      await this.$router.push(editRoute)
    },
  },
}
</script>
