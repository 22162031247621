import { computeMergedTheme, type MergedTheme } from '@owner/theme'
import get from 'lodash/get'
import { inject, computed, type ComputedRef } from 'vue'

import globalStore, { useMappedGetter } from '@/store'

export const useMergedTheme = (): ComputedRef<MergedTheme | undefined> => {
  const coreMergedTheme = useMappedGetter<MergedTheme | undefined>(
    'core/brand/active/mergedTheme'
  )

  const brandThemePath = inject('brandThemePath') as string
  const storeModule = inject('storeModule') as string

  return computed(() => {
    if (brandThemePath) {
      const localTheme = get(
        globalStore.getters[`${storeModule}/currentResource`],
        brandThemePath
      )

      if (localTheme) {
        return computeMergedTheme(localTheme)
      }

      return coreMergedTheme.value
    } else {
      return coreMergedTheme.value
    }
  })
}
