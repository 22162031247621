<i18n locale="en">
{
  "confirm-send": "Are you sure you want to send this campaign now?",
  "titles": {
    "schedule": "Change Schedule",
    "subject": "Update Subject",
    "send": "Send"
  },
  "errors": {
    "update": "There was a problem updating the campaign",
    "schedule": "There was a problem scheduling this campaign"
  }
}
</i18n>

<template>
  <OwnDialog v-if="campaign" v-model="showDialog">
    <template #heading="{ close }">
      <InvalidConfigurationHeader v-if="showWarning" @cancel="close" />
      <OwnDialogHeading
        v-else
        :title="$t(`titles.${action ?? 'send'}`)"
        @cancel="close"
      />
    </template>

    <OwnDialogContent>
      <InvalidConfigurationContent v-if="showWarning" :warnings="warnings" />
      <OwnStack v-else spacing="8">
        <OwnType
          v-if="action === 'send' && campaign.transport !== 'email'"
          :text="$t('confirm-send')"
        />
        <OTCEditor
          v-else
          :date.sync="executeAt"
          :subject.sync="subject"
          :disabled.sync="isDisabledByEditor"
          :edit-schedule="editSchedule"
          :edit-subject="editSubject"
        />
        <OwnRule v-if="hasCustomContent" type="dashed" />
        <slot
          name="custom-content"
          :disable="onToggleDisabledByCustomContent"
          :execute-at="executeAt"
        />
      </OwnStack>
    </OwnDialogContent>

    <template #footer>
      <OwnDialogFooter>
        <InvalidConfigurationFooter
          v-if="showWarning"
          :campaign="campaign"
          @close="showDialog = false"
        />
        <UpdateOTCFooter
          v-else-if="actionType === 'update'"
          :disabled="isDisabled"
          :saving="isSaving"
          @update="onUpdate"
        />
        <ScheduleOTCFooter
          v-else
          :date="executeAt"
          :disabled="isDisabled"
          :subject="subject"
          :campaign="campaign"
          :saving="isSaving"
          @schedule="onSchedule"
        />
      </OwnDialogFooter>
    </template>
  </OwnDialog>
</template>

<script lang="ts" setup>
import { isDefined, useVModel } from '@vueuse/core'
import { ref, computed, watch, onMounted, WritableComputedRef } from 'vue'
import { useSlots } from 'vue'

import { BuilderMethods } from '@/lib/builder'
import { logError } from '@/logger'
import { useMappedGetter, useMappedAction } from '@/store'
import {
  OwnDialog,
  OwnDialogContent,
  OwnDialogFooter,
  OwnDialogHeading,
  OwnRule,
  OwnStack,
  OwnType,
} from '@/ui'
import { useI18n, useNotify } from '@/utils'

import {
  BaseCampaignResource,
  CampaignBlock,
  MarketingStoreModule,
} from '../../../types'
import OTCEditor from '../OTCEditor.vue'
import ScheduleOTCFooter from '../ScheduleOTCFooter.vue'

import InvalidConfigurationContent from './InvalidConfigurationContent.vue'
import InvalidConfigurationFooter from './InvalidConfigurationFooter.vue'
import InvalidConfigurationHeader from './InvalidConfigurationHeader.vue'
import UpdateOTCFooter from './UpdateOTCFooter.vue'

const i18n = useI18n()
const notify = useNotify()

interface SendOTCDialogProps {
  /**
   * Specific sub-operations to perform with the dialog
   */
  action?: 'schedule' | 'subject' | 'send'

  /**
   * When present, will be used for the call to `publishChanges`.
   */
  blockModules?: Array<unknown>

  methods: BuilderMethods<BaseCampaignResource, unknown, unknown, CampaignBlock>

  storeModule: MarketingStoreModule

  /** Show Modal */
  value?: boolean
}

const props = withDefaults(defineProps<SendOTCDialogProps>(), {
  action: undefined,
  blockModules: () => [],
  methods: undefined,
  value: false,
})
const showDialog = useVModel(props) as WritableComputedRef<boolean>
const emit = defineEmits(['submit'])

const slots = useSlots()
// `$slots['custom-content']` in the template doesn't work because it's not reactive, so we use a computed prop instead
const hasCustomContent = computed(() => !!slots['custom-content'])

const executeAt = ref(new Date())
const isDisabledByEditor = ref(false)
const isDisabledByCustomContent = ref(false)
const isDisabled = computed(
  () => isDisabledByEditor.value || isDisabledByCustomContent.value
)
const isSaving = ref(false)
const subject = ref('')

const campaign = useMappedGetter<BaseCampaignResource | undefined>(
  `${props.storeModule}/currentResource`
)
const getBlockById = useMappedGetter<
  (blockId: string) => CampaignBlock | undefined
>(`${props.storeModule}/blockById`)

const actionType = computed(() => {
  return props.action === 'subject' ? 'update' : 'schedule'
})

const editSchedule = computed(() => {
  return !props.action || props.action === 'schedule'
})

const editSubject = computed(() => {
  return (
    campaign.value?.transport === 'email' &&
    (!props.action || props.action === 'send' || props.action === 'subject')
  )
})

const showWarning = computed(() => {
  // We can always allow subject editing
  if (props.action === 'subject') return false

  return !!warnings.value.length
})

const warnings = computed(() => {
  if (!campaign.value) return []

  return campaign.value.sections
    .map((sectionId) => {
      const section = getBlockById.value(sectionId)

      if (section && section.data === null) return section.type

      return null
    })
    .filter(isDefined)
})

const initialize = function () {
  if (!campaign.value) return

  subject.value = campaign.value.name

  if (props.action === 'send' || !campaign.value.executeAt) {
    executeAt.value = new Date()
  } else {
    executeAt.value = new Date(campaign.value.executeAt)
  }
}

const onSchedule = async function () {
  await submitChanges('schedule')
}

const onUpdate = async function () {
  await submitChanges('update')
}

const onToggleDisabledByCustomContent = (newValue: boolean) => {
  isDisabledByCustomContent.value = newValue
}

const publishChanges = useMappedAction(`${props.storeModule}/publishChanges`)
const updateCampaign = useMappedAction(`${props.storeModule}/updateResource`)

const submitChanges = async function (mode: 'schedule' | 'update') {
  try {
    isSaving.value = true

    if (props.action === 'send') executeAt.value = new Date()

    const update: Record<string, unknown> = {
      name: subject.value,
      subject: subject.value,
    }
    if (mode !== 'update') update.executeAt = executeAt

    await updateCampaign(update)

    await publishChanges({
      blocks: props.blockModules,
      methods: props.methods,
    })

    if (mode === 'update') {
      showDialog.value = false
    } else {
      emit('submit')
    }
  } catch (error) {
    logError(error)
    notify(i18n.$t(`errors.${mode}`), 'error')
  } finally {
    isSaving.value = false
  }
}

watch(campaign, initialize)
// Re-initialize when `action` changes
watch(() => props.action, initialize)

onMounted(initialize)
</script>
