import { PhImageSquare } from 'phosphor-vue/dist/phosphor-vue.esm'

import { type BuilderBlockModule } from '@/lib/builder'
import i18n from '@/plugins/i18n'

import {
  type WebsiteResource,
  type GallerySectionCreate,
  type GallerySection,
  type GallerySectionUpdate,
} from '../../WebsiteBuilder.types'
import { websiteBlockMethods } from '../common/methods'

import GalleryEditor from './editor/GalleryEditor.vue'
import { createGalleryData } from './methods/createGalleryData'
import GalleryPreview from './preview/GalleryPreview.vue'

export const GalleryBlockModule: BuilderBlockModule<
  GallerySection,
  GallerySectionCreate,
  GallerySectionUpdate,
  WebsiteResource
> = {
  createData: createGalleryData,
  editor: GalleryEditor,
  meta: [
    {
      icon: PhImageSquare,
      key: 'gallery',
      label: i18n.t('website-builder.blocks.gallery'),
      sort: 1,
    },
  ],
  methods: websiteBlockMethods,
  preview: GalleryPreview,
}
