import { PhTextAlignLeft } from 'phosphor-vue/dist/phosphor-vue.esm'

import { type BuilderBlockModule } from '@/lib/builder'
import i18n from '@/plugins/i18n'

import {
  type CampaignResource,
  type CTAButtonSection,
  type CTAButtonSectionCreate,
  type CTAButtonSectionUpdate,
} from '../../types'
import { emailBlockMethods } from '../common/methods'

import { createCTAButtonData } from './methods/createCTAData'
import CTAButtonPreview from './preview/CTAButtonPreview.vue'

export const CTAButtonBlockModule: BuilderBlockModule<
  CTAButtonSection,
  CTAButtonSectionCreate,
  CTAButtonSectionUpdate,
  CampaignResource
> = {
  canAdd: false,
  canDelete: false,
  canReorderDown: false,
  canReorderUp: false,
  createData: createCTAButtonData,
  meta: [
    {
      icon: PhTextAlignLeft,
      key: 'cta',
      label: i18n.t('email-builder.blocks.cta-section'),
    },
  ],
  methods: emailBlockMethods,
  preview: CTAButtonPreview,
}
