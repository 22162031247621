import { coreActions } from './actions'
import { coreGetters } from './getters'
import globalMutations from './globalMutations'
import { coreMutations } from './mutations'
import { coreState } from './state'
import brandActions from './submodules/brand/actions'
import brandMutations from './submodules/brand/mutations'
import locationActions from './submodules/location/actions'
import locationGetters from './submodules/location/getters'
import locationMutations from './submodules/location/mutations'

const actions = {
  ...coreActions,
  ...brandActions,
  ...locationActions,
}

const getters = {
  ...coreGetters,
  ...locationGetters,
}

const mutations = {
  ...coreMutations,
  ...brandMutations,
  ...locationMutations,
  ...globalMutations,
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state: coreState,
}

export * from './useOauthStore'
