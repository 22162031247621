import merge from 'lodash/merge'
import set from 'lodash/set'

import { useBuilder } from '@/lib/builder'

import { type ContentSection } from '../../../WebsiteBuilder.types'

const websiteBuilderQueue = useBuilder('websiteBuilder')

const ContentCommands = {
  overrideBlockContent: (path: string) =>
    websiteBuilderQueue.createBlockCommand<ContentSection>({
      commit: async (currentData, ctx) => {
        return set(currentData, path, ctx.update)
      },
    }),
  updateBlock: websiteBuilderQueue.createBlockCommand<ContentSection>({
    commit: async (currentData, ctx) => {
      return merge(currentData, ctx.update)
    },
  }),
}

export { ContentCommands }
