import type Vue from 'vue'
import { getCurrentInstance } from 'vue'

export const useI18n = (): Pick<Vue, '$t' | '$tc' | '$te'> => {
  /**
   * Note: `getCurrentInstance` is an internal Vue method and not
   * recommended.  However, we are using it in a controlled
   * way here.  We are only accessing the proxy to use $t and $tc.
   * This is enforced by TS and will provides us a bridge until we
   * get to Vue 3 land.
   */
  const vm = getCurrentInstance() as { proxy: Vue }

  return vm.proxy
}
