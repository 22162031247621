import merge from 'lodash/merge'
import {
  type ActionTree,
  type GetterTree,
  type MutationTree,
  type Module,
} from 'vuex'

import { type CoreModule, type QueueStoreModules } from '../'

export interface StoreExtensionOptions<T> {
  defaultState?: T
  moduleName: QueueStoreModules
}

export interface StoreExtension<S, R> {
  actions: ActionTree<S, R>
  getters: GetterTree<S, R>
  mutations: MutationTree<S>
  state: S
}

const Store = {
  createModule<T, R>(
    extension: StoreExtension<T, R>,
    namespaced: boolean
  ): Module<T, R> {
    return merge(extension, { namespaced })
  },

  extend<T, ExT>(
    moduleDefinition: StoreExtension<T, CoreModule>,
    extension: StoreExtension<ExT, CoreModule>
  ): StoreExtension<T & ExT, CoreModule> {
    return {
      actions: merge(extension.actions, moduleDefinition.actions),
      getters: merge(extension.getters, moduleDefinition.getters),
      mutations: merge(extension.mutations, moduleDefinition.mutations),
      state: merge(extension.state, moduleDefinition.state),
    }
  },
}

export { Store }
