import { PhImage } from 'phosphor-vue/dist/phosphor-vue.esm'

import { type BuilderBlockModule } from '@/lib/builder'
import i18n from '@/plugins/i18n'

import {
  type CampaignResource,
  type ImageSection,
  type ImageSectionCreate,
  type ImageSectionUpdate,
} from '../../types'
import { emailBlockMethods } from '../common/methods'

import ImageEditor from './editor/ImageEditor.vue'
import { createImageData } from './methods/createImageData'
import ImagePreview from './preview/ImagePreview.vue'

export const ImageBlockModule: BuilderBlockModule<
  ImageSection,
  ImageSectionCreate,
  ImageSectionUpdate,
  CampaignResource
> = {
  createData: createImageData,
  editor: ImageEditor,
  meta: [
    {
      icon: PhImage,
      key: 'photo',
      label: i18n.t('email-builder.blocks.image'),
    },
  ],
  methods: emailBlockMethods,
  preview: ImagePreview,
}
