<i18n locale="en">
{
  "pages": {
    "invoices": "Invoices",
    "subscriptions": "Subscriptions",
    "payouts": "Payouts"
  }
}
</i18n>

<template>
  <DropdownNav :nav-items="navItems" />
</template>

<script lang="ts" setup>
import DropdownNav from '@/components/Navigation/DropdownNav.vue'
import { useI18n } from '@/utils'

const i18n = useI18n()

const navItems = [
  {
    label: i18n.$t('pages.invoices'),
    to: { name: `brand.billing.invoices` },
  },
  {
    label: i18n.$t('pages.subscriptions'),
    to: { name: `brand.billing.methods` },
  },
  {
    label: i18n.$t('pages.payouts'),
    to: { name: `brand.payouts` },
  },
]
</script>
