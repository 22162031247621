import { PhClipboardText } from 'phosphor-vue/dist/phosphor-vue.esm'

import { websiteBlockMethods } from '@/features/website-builder/builder/blocks/common/methods'
import { type BuilderBlockModule } from '@/lib/builder'
import i18n from '@/plugins/i18n'

import FormEditor from './editor/FormEditor.vue'
import {
  type FormSectionCreate,
  type FormSection,
  type FormSectionUpdate,
  type WebsiteResource,
} from './FormBlockModule.types'
import { createFormData } from './methods/createFormData'
import FormPreview from './preview/FormPreview.vue'

export const FormBlockModule: BuilderBlockModule<
  FormSection,
  FormSectionCreate,
  FormSectionUpdate,
  WebsiteResource
> = {
  createData: createFormData,
  editor: FormEditor,
  meta: [
    {
      icon: PhClipboardText,
      key: 'form',
      label: i18n.t('website-builder.blocks.form'),
      sort: 5,
    },
  ],
  methods: websiteBlockMethods,
  preview: FormPreview,
}
